import React, { useEffect, useState } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_SOURCE;

const SolveProblem = ({ setShowSolveProblem }) => { // Dodanie propsa
    const [problem, setProblem] = useState(null);
    const [sessionResults, setSessionResults] = useState([]);
    const [userAvgElo, setUserAvgElo] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [showHint, setShowHint] = useState(false);
    const [showSolution, setShowSolution] = useState(false);
    const [hint, setHint] = useState('');
    const [solution, setSolution] = useState('');
    const [answers, setAnswers] = useState([]);
    const [isAnswerChecked, setIsAnswerChecked] = useState(false);

    const token = localStorage.getItem('token');

    const fetchProblem = async () => {
        try {
            const response = await axios.get(`${apiUrl}solve_problem/`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            const { problem, user_avg_elo, session_results } = response.data;
            setProblem(problem);
            setUserAvgElo(user_avg_elo);
            setSessionResults(session_results || []);

            const allAnswers = [...problem.false_answers, problem.true_answer];
            const shuffledAnswers = allAnswers.sort(() => Math.random() - 0.5);
            setAnswers(shuffledAnswers);

            setHint(problem.hint);
            setSolution(problem.solution);
            setIsAnswerChecked(false);
        } catch (error) {
            console.error('Error fetching problem:', error);
        }
    };

    useEffect(() => {
        fetchProblem();
    }, [token]);

    const handleAction = async (action) => {
        try {
            const response = await axios.post(`${apiUrl}solve_problem/`, {
                action,
                selected_answer: selectedAnswer
            }, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });

            if (action === 'check_answer') {
                if (response.data.is_correct) {
                    alert('Correct answer!');
                } else {
                    alert('Wrong answer.');
                }
                setIsAnswerChecked(true);
            } else if (action === 'use_hint') {
                setHint(response.data.hint);
                setShowHint(true);
            } else if (action === 'view_solution') {
                setSolution(response.data.solution);
                setShowSolution(true);
            }
        } catch (error) {
            console.error('Error handling action:', error);
        }
    };

    const handleNextProblem = async () => {
        try {
            const response = await axios.post(`${apiUrl}next_problem/`, {}, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            if (response.data.success) {
                fetchProblem();
                setSelectedAnswer('');
                setShowHint(false);
                setShowSolution(false);
            } else {
                alert('No more problems.');
            }
        } catch (error) {
            console.error('Error moving to next problem:', error);
        }
    };

    // Funkcja do powrotu do Dashboard
    const goBackToDashboard = () => {
        setShowSolveProblem(false); // Zmiana stanu w App.js
    };

    return (
        <div>
            <h1>Solve Problem</h1>
            <p>User Avg ELO: {userAvgElo}</p>
            {problem && (
                <div>
                    <h2>{problem.question}</h2>
                    <form>
                        {answers.map((answer, index) => (
                            <div key={index}>
                                <label>
                                    <input
                                        type="radio"
                                        name="answer"
                                        value={answer}
                                        checked={selectedAnswer === answer}
                                        onChange={() => setSelectedAnswer(answer)}
                                        disabled={isAnswerChecked}
                                    />
                                    {answer}
                                </label>
                            </div>
                        ))}
                    </form>
                    <button onClick={() => handleAction('check_answer')} disabled={isAnswerChecked}>
                        Check Answer
                    </button>
                    <button onClick={() => handleAction('use_hint')}>Use Hint</button>
                    <button onClick={() => handleAction('view_solution')}>View Solution</button>
                    {showHint && <div>Hint: {hint}</div>}
                    {showSolution && <div>Solution: {solution}</div>}
                    
                    {isAnswerChecked && (
                        <button onClick={handleNextProblem}>Next Problem</button>
                    )}
                </div>
            )}
            <h3>Session Results:</h3>
            <ul>
                {sessionResults.map((result, index) => (
                    <li key={index}>Result {index + 1}: {result}</li>
                ))}
            </ul>
            {/* Przycisk do powrotu */}
            <button onClick={goBackToDashboard} className="btn">Powrót do dashboardu</button>
        </div>
    );
};

export default SolveProblem;
