import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_SOURCE;

const Dashboard = ({ setShowUserStats, setShowSolveProblem,setShowFieldsPreferences  }) => {
    const [data, setData] = useState(null);
    const [newCategory, setNewCategory] = useState('');
    const [difficulty, setDifficulty] = useState('Średni');
    const [mobileProblems, setMobileProblems] = useState(false);
    const [endlessMode, setEndlessMode] = useState(false);
    const [testMode, setTestMode] = useState(false);

    const token = localStorage.getItem('token');
    const handleShowFieldsPreferences = () => {
        setShowFieldsPreferences(true); // Ustaw stan, aby wyświetlić FieldsPreferences
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}dashboard/`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                setData(response.data);
                setDifficulty(response.data.preferences.level_modifier === -300 ? 'Łatwy' : response.data.preferences.level_modifier === 300 ? 'Trudny' : 'Średni');
                setMobileProblems(response.data.preferences.mobile_problems);
                setEndlessMode(response.data.preferences.endless_mode);
                setTestMode(response.data.preferences.test_mode);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [token]);

    const handleAddCategory = async () => {
        try {
            await axios.post(`${apiUrl}dashboard/`, {
                add_category: true,
                category: newCategory
            }, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            window.location.reload();
        } catch (error) {
            console.error('Error adding category:', error);
        }
    };

    const handleRemoveCategory = async (categoryId) => {
        try {
            await axios.post(`${apiUrl}dashboard/`, {
                remove_category: true,
                category: categoryId
            }, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            window.location.reload();
        } catch (error) {
            console.error('Error removing category:', error);
        }
    };

    const handleSolveProblems = async () => {
        try {
            const response = await axios.post(`${apiUrl}solve_problems/`, {
                initialize_problems: true,
                difficulty,
                mobile_problems: mobileProblems,
                endless_mode: endlessMode,
                test_mode: testMode
            }, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            setShowSolveProblem(true); // Przełącz widok na SolveProblem
        } catch (error) {
            console.error('Error solving problems:', error);
        }
    };

    const goToStats = () => {
        setShowUserStats(true);
    };

    if (!data) return <div>Loading...</div>;

    return (
        <div className="container">
            <div className="left">
                <h1>Rozwiązuj zadania!</h1>
                <p>Wybrane kategorie:</p>
                <table>
                    {data.selected_categories.map(category => (
                        <tr key={category.field_id}>
                            <td>{category.field_name}</td>
                            <td>{category.user_field_elo}</td>
                            <td>
                                <button onClick={() => handleRemoveCategory(category.field_id)} className="btn btn-remove">Usuń</button>
                            </td>
                        </tr>
                    ))}
                </table>

                <select onChange={(e) => setNewCategory(e.target.value)} value={newCategory}>
                    <option value="">Wybierz kategorię</option>
                    {data.available_categories.map(category => (
                        <option key={category.field_id} value={category.field_id}>
                            {category.field_name}
                        </option>
                    ))}
                </select>
                <button onClick={handleAddCategory} className="btn">Dodaj kategorię</button>
                <button onClick={handleShowFieldsPreferences}>Zarządzaj Polami</button>

                <form onSubmit={(e) => { e.preventDefault(); handleSolveProblems(); }}>
                    <label htmlFor="difficulty">Poziom trudności:</label>
                    <select value={difficulty} onChange={(e) => setDifficulty(e.target.value)}>
                        <option value="Łatwy">Łatwy</option>
                        <option value="Średni">Średni</option>
                        <option value="Trudny">Trudny</option>
                    </select>

                    <label htmlFor="mobile_problems">Zadania bez obliczeń</label>
                    <input type="checkbox" checked={mobileProblems} onChange={(e) => setMobileProblems(e.target.checked)} />

                    <label htmlFor="endless_mode">Tryb bez końca</label>
                    <input type="checkbox" checked={endlessMode} onChange={(e) => setEndlessMode(e.target.checked)} />

                    <label htmlFor="test_mode">Tryb testowy</label>
                    <input type="checkbox" checked={testMode} onChange={(e) => setTestMode(e.target.checked)} />

                    <button type="submit" className="btn">Rozwiązuj!</button>
                </form>
            </div>
            <div className="right">
                <h1>Świetnie {data.username}!</h1>
                <p>Rozwiązałeś z nami już {data.problems_count} zadań. Twój obecny ranking to {data.average_user_elo}.</p>

                <button onClick={goToStats} className="btn">Zobacz swoje statystyki</button>
            </div>
        </div>
    );
};

export default Dashboard;
