import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const apiUrl = process.env.REACT_APP_API_SOURCE;


const UserStats = ({ setShowUserStats }) => {
    const [dates, setDates] = useState([]);
    const [problemsAttempted, setProblemsAttempted] = useState([]);
    const [problemsSolved, setProblemsSolved] = useState([]);
    const [avgEloGain, setAvgEloGain] = useState([]);
    const handleBackToDashboard = () => {
        setShowUserStats(false); // Przełącz stan, aby wrócić do Dashboard
    };

    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}user_stats/`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                setDates(response.data.dates);
                setProblemsAttempted(response.data.problems_attempted);
                setProblemsSolved(response.data.problems_solved);
                setAvgEloGain(response.data.avg_elo_gain);
            } catch (error) {
                console.error('Error fetching stats:', error);
            }
        };

        fetchData();
    }, [token]);

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Count'
                }
            }
        }
    };

    return (
        <div className="container">
            <h1>Twoje Statystyki</h1>

            <div className="chart-container">
                <h2>Próby rozwiązania zadań</h2>
                <Line
                    data={{
                        labels: dates,
                        datasets: [
                            {
                                label: 'Próby rozwiązania zadań',
                                data: problemsAttempted,
                                borderColor: 'rgba(75, 192, 192, 1)',
                                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                                fill: true
                            }
                        ]
                    }}
                    options={chartOptions}
                />
            </div>

            <div className="chart-container">
                <h2>Rozwiązane zadania</h2>
                <Line
                    data={{
                        labels: dates,
                        datasets: [
                            {
                                label: 'Rozwiązane zadania',
                                data: problemsSolved,
                                borderColor: 'rgba(54, 162, 235, 1)',
                                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                fill: true
                            }
                        ]
                    }}
                    options={chartOptions}
                />
            </div>

            <div className="chart-container">
                <h2>Średni wzrost ELO</h2>
                <Line
                    data={{
                        labels: dates,
                        datasets: [
                            {
                                label: 'Średni wzrost ELO',
                                data: avgEloGain,
                                borderColor: 'rgba(153, 102, 255, 1)',
                                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                                fill: true
                            }
                        ]
                    }}
                    options={chartOptions}
                />
            </div>
            <button onClick={handleBackToDashboard} className="btn">Powrót do Dashboardu</button>
        </div>
    );
};

export default UserStats;
