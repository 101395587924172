import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_SOURCE;

const FieldsPreferences = ({ setShowFieldsPreferences }) => {
    const [userFields, setUserFields] = useState([]);
    const [availableFields, setAvailableFields] = useState([]);
    const [selectedFieldId, setSelectedFieldId] = useState('');

    const handleBackToDashboard = () => {
        setShowFieldsPreferences(false); // Przełącz stan, aby wrócić do Dashboard
    };

    useEffect(() => {
        const fetchFields = async () => {
            try {
                const response = await axios.get(`${apiUrl}fields_preferences/`, {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('token')}`
                    }
                });
                setUserFields(response.data.user_fields);
                setAvailableFields(response.data.available_fields);
            } catch (error) {
                console.error('Error fetching fields:', error);
            }
        };

        fetchFields();
    }, []);

    const handleAction = async (action, fieldId) => {
        try {
            await axios.post(`${apiUrl}fields_preferences/`, {
                action,
                field_id: fieldId
            }, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`
                }
            });
            // Refresh data after action
            const response = await axios.get(`${apiUrl}fields_preferences/`, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`
                }
            });
            setUserFields(response.data.user_fields);
            setAvailableFields(response.data.available_fields);
        } catch (error) {
            console.error('Error handling action:', error);
        }
    };

    return (
        <div className="container">
            <h1>Zarządzanie Polami</h1>

            <h2>Twoje Pola</h2>
            {userFields.map(userField => (
                <div key={userField.id} className="field-item">
                    <h3>{userField.field_name} - Poziom ELO: {userField.user_field_elo}</h3>
                    <div className="field-details">
                        Nauka: {userField.science}<br />
                        Dziedzina: {userField.domain}<br />
                        Poziom Wiedzy: {userField.knowledge_level}<br />
                        Status: 
                        {userField.is_visible ? (
                            <span className="status visible">Widoczne</span>
                        ) : (
                            <span className="status hidden">Ukryte</span>
                        )}
                    </div>
                    <div className="field-actions">
                        {userField.is_visible ? (
                            <button onClick={() => handleAction('hide_field', userField.id)}>Ukryj</button>
                        ) : (
                            <button onClick={() => handleAction('restore_field', userField.id)}>Przywróć</button>
                        )}
                        <button onClick={() => handleAction('reset_field', userField.id)}>Resetuj Poziom</button>
                    </div>
                </div>
            ))}

            <h2>Dodaj Nowe Pole</h2>
            <form onSubmit={(e) => {
                e.preventDefault();
                handleAction('add_field', selectedFieldId);
            }}>
                <label htmlFor="field_id">Wybierz pole:</label>
                <select 
                    id="field_id" 
                    value={selectedFieldId} 
                    onChange={(e) => setSelectedFieldId(e.target.value)}
                >
                    {availableFields.map(field => (
                        <option key={field.field_id} value={field.field_id}>
                            {field.field_name} - {field.science} - {field.domain}
                        </option>
                    ))}
                </select>
                <button type="submit">Dodaj</button>
            </form>
            <button onClick={handleBackToDashboard} className="btn">Powrót do Dashboardu</button>
        </div>
    );
};

export default FieldsPreferences;
