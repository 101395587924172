// src/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_SOURCE;

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    console.log('login_page');
    console.log(apiUrl);

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(apiUrl+'login/', {
                username,
                password
            });

            if (response.status === 200) {
                const { token, preferences } = response.data;

                // Zapisz token i preferencje użytkownika w localStorage
                localStorage.setItem('token', token);
                localStorage.setItem('user_id', preferences.user_id);
                localStorage.setItem('user_preferences', JSON.stringify(preferences));

                // Przekieruj do strony głównej
                navigate('/');
            }
        } catch (error) {
            setError('Błędna nazwa użytkownika lub hasło');
        }
    };

    return (
        <div>
            <h2>Logowanie</h2>
            <form onSubmit={handleLogin}>
                <div>
                    <label>Nazwa użytkownika:</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Hasło:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <button type="submit">Zaloguj</button>
            </form>
        </div>
    );
};

export default Login;