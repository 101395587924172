// App.js
import React, { useState, useEffect }from 'react';
import { BrowserRouter  as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import SolveProblem from './SolveProblem';
import UserStats from './UserStats';
import FieldsPreferences from './FieldsPreferences'; // Dodaj import



const App = () => {
    const [showUserStats, setShowUserStats] = useState(false);
    const [showSolveProblem, setShowSolveProblem] = useState(false);
    const [showFieldsPreferences, setShowFieldsPreferences] = useState(false); // Dodaj stan
    // const token = localStorage.getItem('token');
    // const navigate = useNavigate();
    console.log(showUserStats, showSolveProblem, showFieldsPreferences);
    // console.log(token);

    // useEffect(() => {
    //     if (!token) {
    //         navigate('/login'); // Przekieruj na stronę logowania, jeśli token nie istnieje
    //     }
    // }, [token, navigate]);

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                    path="/"
                    element={
                        showUserStats ? (
                            <UserStats setShowUserStats={setShowUserStats} />
                        ) : showSolveProblem ? (
                            <SolveProblem setShowSolveProblem={setShowSolveProblem} />
                        ) : showFieldsPreferences ? (
                            <FieldsPreferences setShowFieldsPreferences={setShowFieldsPreferences}/>
                        ) : (
                            <Dashboard 
                                setShowUserStats={setShowUserStats} 
                                setShowSolveProblem={setShowSolveProblem}
                                setShowFieldsPreferences={setShowFieldsPreferences} // Dodaj przekazywanie stanu
                            />
                        )
                    }
                />
            </Routes>
        </Router>
    );
};

export default App;
